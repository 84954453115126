import React from 'react';
import './index.css'

export default function Card({title, description, icon}){

    return(
        <div class="grid-result">
            <div className='icon-container'>
                <img src={icon} alt='logo'/>
            </div>
            <div >
                <h2 className="font-bold">
                    {title}
                </h2>
                <p>
                    {description}
                </p>
            </div>
        </div>
    )

}