import React, { useEffect, useState } from 'react';
import news from '../../utils/objects/news.json'
import './index.css'

export default function News(){

    const [newsObj, setNews] = useState([])
    const [fetched, setFetched] = useState(false)

    function concatString(text){
        if (text.length > 100) {
            return text.substring(0, 100) + "...";
        }
        return text + "...";
    }
    useEffect(()=>{
        console.log(`${news.news[0].image}`);
        setNews(news.news)
        setFetched(true)
    }, [])
    return(
        <div className='w-full'>
            <h1 className="news-title text-[40px] p-12 bg-blue-400 text-white font-bold">
                NEWS
            </h1>
            <div className='flex justify-center'>
                <div className='news-container m-3 w-3/4'>
                    <div className='news-grid'>
                        { fetched === true && 
                            newsObj.map((item) => {
                                return(
                                <a href={`news/${item.title}`} className='news-card text-left'>
                                    <img src={require('../../resources/images/news/'+ item.images[0])} alt='news-display'/>
                                    <p className='p-2 text-[12px] font-bold text-[#33333370]'>{item.date}</p>
                                    <h2 className='font-bold'>
                                        {item.title}
                                    </h2>
                                    <p className="text-[13px]">
                                        {concatString(item.description)}
                                    </p>
                                </a>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}