import React, { useState } from 'react';
import logo from '../../resources/images/logos/logo.png'
import facebook from '../../resources/images/icons/facebook.svg'
import twitter from '../../resources/images/icons/twitterx.svg'
import youtube from '../../resources/images/icons/utube.png'
import login from '../../resources/images/icons/navigation/icons8-login-100.png'
import DropdownButton from './DropdownButton'
import './index.css'

export default function Navigation() {

  const [openDrop, setOpenDrop] = useState(false)

    return (
        <header className='navigation h-28 w-full  flex items-center bg-slate-50 drop-shadow'>
          <a className='logo gap-5 flex align-bottom items-end' href='/'>
            <img src={logo} alt='sdgs4u logo' className='h-20 pl-5'/>
            <div className='title-head w-fit h-fit text-left text-[1.5rem] text-[#007fc9]'>
              <b>
                <h2>
                  Engaging SDGs for Transformative Education 
                </h2>
                <h2>
                  and Enhanced Sustainability in Universities.
                </h2>
              </b>
            </div>
          </a>
          
          <div className='social-out absolute w-fit right-20 top-5 h-fit flex gap-3 justify-center items-center'>
            
            
            {/* <div className='social h-fit flex items-center'>
              <a href='/'>
                <img src={facebook} alt='facebook logo' className='h-6'/>
              </a>
            </div>
            <div className='social h-fit flex items-center'>
              <a href='/'>
                <img src={twitter} alt='x logo' className='h-6'/>
              </a>
            </div>
            <div className='social h-fit flex items-center'>
              <a href='/'>
                <img src={youtube} alt='youtube logo' className='h-6'/>
              </a>
            </div> */}
          </div> 
          
          <div className='selections absolute w-fit items-center right-20 bottom-3 h-fit flex gap-7 text-menuGreen'>
            <div className='h-fit flex items-center'>
              {/* <a href='/'> HOME </a> */}
              <span class="material-symbols-outlined">
                <a href='/' >home</a>
              </span>
            </div>
            <div className='h-fit flex items-center '>
              <a href='/about'> ABOUT </a>
            </div>
            <div className='h-fit flex items-center'>
              <a href='/partners'> PARTNERS </a>
            </div>
            <div className='h-fit flex items-center'>
              <a href='/news'> NEWS </a>
            </div>
            {/* <div className='h-fit flex items-center'>
              <a href='/dissemination'> DISSEMINATION </a>
            </div> */}
            {/* <div className='deliverables-but h-fit flex items-center'>
              <a onClick={() => setOpenDrop((prev)=> !prev)}> DELIVERABLES </a>
            </div> */}
            {/* <div className='h-fit flex items-center'>
              <a href='/contact'> MAPPING_TOOL</a>
            </div> */}
            {/* <div className='h-fit flex items-center'>
              <a href='https://shared.sdg4u.scanlab.gr/login'target="_blank" rel="noreferrer" > 
                <img src={login} width={20} alt='login icon'/>
              </a>
            </div> */}
            { openDrop && <DropdownButton/> }
          </div>
        </header>  
    )
  }