import React from 'react';
import gear from '../../resources/images/icons/gear.png'
import progress from '../../resources/images/icons/progress.png'
import bookIcon from '../../resources/images/icons/book.png'
import people from '../../resources/images/icons/people2.png'
import paperplane from '../../resources/images/icons/paperplane.png'
import plant from '../../resources/images/icons/plant.png'
import logo from '../../resources/images/logos/logo.png'
import Card from './Card';
import './index.css'

export default function Home(){

    return (
        <div className='home'>
            <div className='menu flex'>
                <div className='blur-background'/>
                <div className='container'>
                    <h1 className='title relative text-black'>
                        
                    </h1>
                    {/* <a href='/about' className="bg-white">
                        Learn More
                    </a> */}
                </div>
                
            </div>

            <div className='modules h-fit flex flex-col items-center justify-center mt-6'>
              <a href='https://sdg4u.uoa.gr/mapping_tool' target="_blank" rel="noreferrer">
                <button className="map-btn bg-menuGreen text-white p-3 text-2xl rounded shadow-lg hover:bg-green-700 transition duration-300 ease-in-out">
                  Mapping Tool
                </button>
              </a>
              <p className='text-2xl m-4'>
              We invite you to start the survey on SDGs engagement in courses
              </p>
            </div>

            <div className='project-info'>
                <div className='info-box'>
                    <div className='box-line'>
                        <h2 id='vision'>VISION</h2>
                        <p>
                            SDG4U’s key priority is to stimulate innovative learning and teaching practices through engaging SDGs to transformative education and sustainability practices in universities. The project activities will enable access to knowledge and skills required for understanding and promoting sustainable development, through formal education, life-long learning programs and initiatives with emphasis on promoting equality, on developing a culture of peace, non-violence and fairness at all levels.
                        </p>
                    </div>
                    <div className='box-line'>
                            <h2 id='mission'>OBJECTIVES</h2>
                            <ul>
                                <li>
                                    ○ To stimulate innovative learning and teaching practices and continuous improvement
                                </li>
                                <li>
                                    ○ To cultivate a sustainable organizational culture based on transparency, mutual support and collegiality
                                </li>
                                <li>
                                    ○ To promote sustainability citizenship amongst faculty, student and staff population
                                </li>
                                <li>
                                    ○ To help initiate Green Campus programs that engage faculty, students and staff and are research informed and practice focused
                                </li>
                                <li>
                                    ○ To create an online mapping tool for SDGs in higher education
                                </li>
                                <li>
                                    ○ To develop initiatives on SDG mapping tools and standardize good practices
                                </li>
                                <li>
                                    ○ To educate students in practical implementations on SDGs
                                </li>
                                <li>
                                    ○ To identify modules/courses that include SDGs in existing academic material
                                </li>
                                <li>
                                    ○ To shape potential modules to be included in existing/new courses
                                </li>
                                <li>
                                    ○ To create pedagogical material for a seminar and train SDG facilitators
                                </li>
                                <li>
                                    ○ To promote initiatives for advancing sustainability of universities
                                </li>
                                <li>
                                    ○ To raise public awareness of project achievements and results
                                </li>
                                <li>
                                    ○ To empower and implement transformative learning and experience
                                </li>
                            </ul>
                    </div>
                </div>
            </div>
            <div className='project-info'>
                <div className='info-box'>
                    <div className='flex justify-center items-center gap-5'>
                        <img src={logo} className='logo-extra'/>
                        <h1 id='results'>EXPECTED RESULTS</h1>
                    </div>
                    <div className='grid-results'>
                        <Card 
                        title={"ONLINE SDGs MAPPING TOOL"} 
                        description={"Development of an online SDGs mapping tool using initiatives that can be standardized as good practices and used to enhance knowledge, perception, behavioral change, and policy making on SDGs."}
                        icon={gear}
                        />
                        <Card 
                        title={"MAPPING PROCESS ONLINE SDGs MAPPING TOOL"} 
                        description={"Implementation of the mapping tool across partner institutions and data collection on modules/courses, research, practices and policies interating SGDs or in accordance with them."}
                        icon={progress}
                        />
                        <Card 
                        title={"DESIGN OF NEW MODULES / COURSES"} 
                        description={"The design of new academic modules/courses developed as individual or joined programs. Institutions will be able to choose modules/courses that focus on specific SDGs."}
                        icon={bookIcon}
                        />
                        <Card 
                        title={"SEMINARS ON SDGs"} 
                        description={"The development of LLL interdisciplinary seminars on SDGs fostering understanding across sciences and relationships of cooperation and knowledge transfer between institutions."}
                        icon={people}
                        />
                        <Card 
                        title={" PROPOSING INITIATIVES"} 
                        description={"Proposing initiatives for advancing sustainability of universities. The outcomes will help institution design SDG friendly strategy that will have an impact to the sustainability of participating organizations."}
                        icon={paperplane}
                        />
                        <Card 
                        title={"RAISE AWARENESS"} 
                        description={"A promotion plan of all expected results will be developed and implemented to reach target groups and the public, to raise awareness on the relevance of developing sustainable practices and culture."}
                        icon={plant}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}