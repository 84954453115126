import React from 'react';
import homeLogo from '../../resources/images/icons/navigation/icons8-home.svg'
import aboutLogo from '../../resources/images/icons/navigation/icons8-magnifier-50.png'
import partnersLogo from '../../resources/images/icons/people2.png'
import newsLogo from '../../resources/images/icons/navigation/icons8-news-50.png'
import contactLogo from '../../resources/images/icons/paperplane.png'
import './index.css'

export default function NavigationMob(){
    return(
    <div className='menu-mobile fixed bottom-3 z-10 w-screen h-16'>
        <div className="flex items-center justify-center h-full w-auto">
          <div className="menu-mobile-inner flex items-center justify-center h-full w-3/4 bg-[#313444f6]">
              <a href="/">
                <img src={homeLogo} alt="logo" className="home-span-icon text-white bg-slate-500 p-3"/>
              </a>
              <a href="/about">
                <img src={aboutLogo} alt="logo" className="home-span-icon text-white bg-slate-500 p-3"/>
              </a>
              <a href="/partners">
                <img src={partnersLogo} alt="logo" className="home-span-icon text-white bg-slate-500 p-3"/>
              </a>
              <a href="/news">
                <img src={newsLogo} alt="logo" className="home-span-icon text-white bg-slate-500 p-3"/>
              </a>
              {/* <a href="https://sdg4u.uoa.gr/mapping_tool">
                <img src={contactLogo} alt="logo" className="home-span-icon text-white bg-slate-500 p-3"/>
              </a> */}
          </div>
        </div>
      </div>
    )
}