import { BrowserRouter, Route, Routes } from "react-router-dom";
import {Helmet} from "react-helmet";
import { useCookies } from "react-cookie";

import './App.css';
import './animations.css'
import Navigation from "./components/Navigation";
import Home from "./components/Home";
import Footer from "./components/Footer";
import About from "./components/About";
import Contact from "./components/Contact";
import Partners from "./components/Partners";
import Partner from "./components/Partner";
import News from "./components/News";
import New from "./components/News/New";
import NavigationMob from "./components/NavigationMob";
import CookieConsent from "./components/CookieConsent";


function App() {

  const [cookies] = useCookies(["cookieConsent"]);

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>SDG4U</title>
        <meta 
            name='description'
            content='SDG4U. Fostering Sustainability Across Academia'  
          >
          </meta>
          <meta name='keywords' content='Eco-friendly, sustainability, renewable, green, responsible, conservation, environmentally conscious, carbon-neutral, recyclable, energy-efficient, organic, regenerative, ethical, low-impact, eco-conscious, sustainable, biodiverse, zero-waste, mindful, circular, climate-friendly'></meta>
      </Helmet>
      <Navigation/>
      <NavigationMob/>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            {/* <Route path="/contact" element={<Contact/>}/> */}
            <Route path="/partners" element={<Partners/>}/>
            <Route path='/partners/:name' element={<Partner/>}/>
            <Route path='/news' element={<News/>}/>
            <Route path='/news/:title' element={<New/>}/>
        </Routes>
      </BrowserRouter>
      <Footer/>
      {!cookies.cookieConsent && <CookieConsent />}
    </div>
  );
}

export default App;
