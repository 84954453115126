import React from 'react';
import './index.css'


export default function About(){

    return(
        <>
        <div>
            <h1 className="about-title text-[40px] p-12 bg-[#5FD5F3] text-white font-bold">
                ABOUT THE PROJECT
            </h1>
            <div className="w-full h-full mt-5 mb-5">
                <div className="about-info w-2/3 h-fit bg-[#f8f8f8] m-auto text-left p-5">
                    <h2 className='text-[20px] font-bold'>TITLE</h2>
                    <h2 className="text-[25px]">
                        <b>Engaging SDGs for Transformative Education and Enhanced Sustainability in Universities (SDG4U)</b>
                    </h2>
                    <div className="mt-8">
                        <h2 className='text-[20px] font-bold'>DESCRIPTION</h2>
                        <p className="text-18px] font-normal text-justify">
                            SDG4U key priority is to stimulate innovative learning 
                            and teaching practices through engaging SDGs to transformative education 
                            and sustainability practices in universities. The project will support 
                            and enhance the implementation of SDGs in higher education
                            by embedding sustainability across teaching, research, operations, 
                            and community outreach.
                        </p>
                        <br/>
                        The project tackles the following goals:
                        <ul>
                                <li>
                                    ○ To stimulate innovative learning and teaching practices and continuous improvement
                                </li>
                                <li>
                                    ○ To cultivate a sustainable organizational culture based on transparency, mutual support and collegiality
                                </li>
                                <li>
                                    ○ To promote sustainability citizenship amongst faculty, student and staff population
                                </li>
                                <li>
                                    ○ To help initiate Green Campus programs that engage faculty, students and staff and are research informed and practice focused
                                </li>
                                <li>
                                    ○ To create an online mapping tool for SDGs in higher education
                                </li>
                                <li>
                                    ○ To develop initiatives on SDG mapping tools and standardize good practices
                                </li>
                                <li>
                                    ○ To educate students in practical implementations on SDGs
                                </li>
                                <li>
                                    ○ To identify modules/courses that include SDGs in existing academic material
                                </li>
                                <li>
                                    ○ To shape potential modules to be included in existing/new courses
                                </li>
                                <li>
                                    ○ To create pedagogical material for a seminar and train SDG facilitators
                                </li>
                                <li>
                                    ○ To promote initiatives for advancing sustainability of universities
                                </li>
                                <li>
                                    ○ To raise public awareness of project achievements and results
                                </li>
                                <li>
                                    ○ To empower and implement transformative learning and experience
                                </li>
                            </ul>
                        <p className="text-18px] font-normal text-justify mt-4">
                            The project activities will enable access to knowledge and skills required 
                            for understanding and promoting sustainable development, 
                            through formal education, life-long learning programs and initiatives with 
                            emphasis on promoting equality,
                            on developing a culture of peace, non-violence and fairness at all levels.
                        </p>
                        <h2 className='text-[20px] font-bold mt-4'>PARTNERS</h2>
                        <p className="text-18px] font-normal text-justify">
                            The project is implemented by a consortium composed 
                            by National and Kapodistrian University of Athens (coordinator), 
                            Panteion University, University College Cork – National University of Ireland, University of Murcia, The American College of Greece Research Centre, 
                            UNIMED – Mediterranean Universities Union.
                        </p>
                        <h2 className='text-[20px] font-bold mt-4'>DURATION</h2>
                        <p className="text-18px] font-normal text-justify">
                            31/12/2022 – 30/12/2025
                        </p>
                        <h2 className='text-[20px] font-bold mt-4'>FUNDING SCHEME</h2>
                        <p className="text-18px] font-normal text-justify">
                            The project is funded by the Erasmus+ programme of the European Union.
                        </p>
                    </div>
                </div>
            </div>  
        </div>
        </>
    )
}